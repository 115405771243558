import React from "react";
import Layout from "../comps/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import {
  AboutUsSection,
  Hero,
  InfoSection,
} from "../styles/pages/privacy-policy";
import PiezitaOne from "../images/icons/piezita-one.png";
import { PackageIcon, Mail } from "styled-icons/octicons";
import { ShieldAlt2 } from "styled-icons/boxicons-regular";
import { HeartFill } from "styled-icons/bootstrap";
import { useTranslation } from "react-i18next";

const Pledge = () => {
  const { t } = useTranslation("policy");

  return (
    <>
      <Seo
        title="En nuestra plataforma de talento, tus datos están seguros."
        description="Contamos con una política de privacidad que respalda tu seguridad mientras navegas en nuestra plataforma de talento."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <Hero>
          <div className="main-landing-container-row">
            <article>
              <h1>{t("heroTitle")}</h1>
              <p>{t("heroDesc")}</p>
            </article>
          </div>
        </Hero>
        <AboutUsSection>
          <div className="main-landing-container-column">
            <p className="main-subtitle">{t("termsFirstDesc")}</p>
            <div className="about-us-resume">
              <article>
                <h2>{t("firstStepTitle")}</h2>
                <p>
                  {t("firstStepDescription1")}{" "}
                  <span>{t("firstStepDescriptionBold1")}</span>
                </p>
                <p>{t("firstStepDescription2")}</p>
              </article>
              <figure>
                <img src={PiezitaOne} alt="Figura de LaPieza" />
              </figure>
            </div>
            <div className="about-us-list">
              <article>
                <PackageIcon size={32} color="#41416E" />
                <h5>{t("firstStepItemTitle1")}</h5>
                <p>{t("firstStepItemDescription1")}</p>
              </article>
              <article>
                <ShieldAlt2 size={32} color="#41416E" />
                <h5>{t("firstStepItemTitle2")}</h5>
                <p>{t("firstStepItemDescription2")}</p>
              </article>
              <article>
                <Mail size={32} color="#41416E" />
                <h5>{t("firstStepItemTitle3")}</h5>
                <p>{t("firstStepItemDescription3")}</p>
              </article>
            </div>
            <article className="about-us-footer">
              <HeartFill size={32} color="#F48221" />
              <h3>{t("firstMessage")}</h3>
            </article>
          </div>
        </AboutUsSection>
        <InfoSection>
          <div className="info-section main-landing-container-column">
            <h2>{t("heroTitle")}</h2>
            <p>{t("termsFirstDesc")}</p>
            <div className="info-section-list">
              <article>
                <h5>{t("termsSecondTitle")}</h5>
                <p>{t("termsSecondDesc")}</p>
              </article>
              <article>
                <h5>{t("termsThirdTitle")}</h5>
                <p>{t("termsThirdDesc")}</p>
              </article>
              <article>
                <h5>{t("termsFourthTitle")}</h5>
                <p>{t("termsFourthDesc")}</p>
              </article>
              <article>
                <h5>{t("termsTitle5")}</h5>
                <p>{t("termsDesc5")}</p>
              </article>
              <article>
                <h5>{t("termsTitle6")}</h5>
                <p>{t("termsDesc6")}</p>
              </article>
              <article>
                <h5>{t("termsTitle7")}</h5>
                <p>{t("termsDesc7")}</p>
              </article>
              <article>
                <h5>{t("termsTitle8")}</h5>
                <p>{t("termsDesc8")}</p>
              </article>
              <article>
                <h5>{t("termsTitle9")}</h5>
                <p>{t("termsDesc9")}</p>
              </article>
              <article>
                <h5>{t("termsTitle10")}</h5>
                <p>{t("termsDesc10")}</p>
              </article>
              <article>
                <h5>{t("termsTitle11")}</h5>
                <p>{t("termsDesc11")}</p>
              </article>
              <article>
                <h5>{t("termsTitle12")}</h5>
                <p>{t("termsDesc12")}</p>
              </article>
              <article>
                <h5>{t("termsTitle13")}</h5>
                <div dangerouslySetInnerHTML={{ __html: t("termsDesc13") }} />
              </article>
            </div>
          </div>
        </InfoSection>
      </Layout>
    </>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
