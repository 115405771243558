import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

const imageHero =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Fbg-privacy.png?alt=media&token=d6b81bcd-fe6b-4de3-80a5-935d0adaef13";

export const Hero = styled.header`
  background-image: url("${imageHero}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 47rem;
  position: relative;
  padding: 8rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 0 16px;
  }

  ::after {
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${landingPalette.blue};
    opacity: 0.33;
  }

  h1 {
    font-size: 23px;
    line-height: 29px;
    color: white;
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 16px;
  }

  p {
    font-size: 5.8rem;
    line-height: 6.4rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    max-width: 66rem;

    @media (max-width: 425px) {
      font-size: 5.2rem;
    }

    @media (max-width: 375px) {
      font-size: 4.8rem;
    }
  }

  article {
    position: relative;
    z-index: 1;
  }
`;

export const AboutUsSection = styled.section`
  padding: 36px 80px 10rem 80px;
  background-color: ${landingPalette.bgBlue};

  @media (max-width: 600px) {
    padding: 36px 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 36px 16px 10rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  .main-subtitle {
    font-family: ${fonts.SofiaProMedium};
    font-size: 23px;
    line-height: 29px;
    color: ${landingPalette.blue};
    margin-bottom: 6.4rem;
  }

  .about-us-resume {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: auto auto;
    margin-bottom: 10rem;

    @media (max-width: 920px) {
      grid-gap: 6rem;
      grid-template-columns: 1fr;
    }

    article {
      width: 100%;
      max-width: 61.5rem;
      align-self: flex-start;
      justify-content: flex-start;

      h2 {
        font-family: ${fonts.SofiaProSemiBold};
        font-size: 38px;
        line-height: 48px;
        color: ${landingPalette.blue};
        margin-bottom: 32px;
      }

      p {
        font-family: ${fonts.sofiaProRegular};
        font-size: 18px;
        line-height: 28px;
        color: ${landingPalette.blue};
        :nth-child(2) {
          margin-bottom: 30px;
        }

        span {
          font-family: ${fonts.SofiaProSemiBold};
        }
      }
    }

    figure {
      max-width: 17.5rem;
      width: 100%;
      align-self: center;
      justify-self: center;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .about-us-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(288px, 100%), 288px));
    grid-gap: 8rem 3.2rem;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10rem;

    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 16px;

      h5 {
        font-size: 2rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.sofiaProRegular};
      }
    }
  }

  .about-us-footer {
    display: flex;
    align-items: center;
    grid-gap: 16px;

    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.sofiaProRegular};
      max-width: 50rem;
    }
  }
`;

export const InfoSection = styled.section`
  padding: 12rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  .info-section {
    > h2 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 2.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      margin-bottom: 1.6rem;
    }

    > p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 2.3rem;
      line-height: 2.9rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 4rem;
    }
  }

  .info-section-list {
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;

    h5 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 1.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      margin-bottom: 2.4rem;
    }

    b {
      font-family: ${fonts.SofiaProBold};
    }

    h6 {
      font-family: ${fonts.sofiaProRegular};
      font-size: 1.6rem;
      color: ${landingPalette.blue};
      margin-bottom: 10px;
    }

    ul {
      font-family: ${fonts.sofiaProRegular};
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 1rem;
    }

    p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 4rem;
    }
  }
`;
